.ErrorDisplay {
  background: #121212;
  height: 100%;
  width: 100%;
}

.ErrorDisplay .bg {
  align-items: center;
  background-image: url("/static/images/TankFlip.gif");
  background-size: cover;
  background-position-x: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 390px);
}

@media screen and (--medium-viewport) {
  .ErrorDisplay .bg {
    height: 100vh;
  }
}

.textContainer {
  text-align: center;
  margin: 1rem auto;
}

.title {
  font-size: 4rem;
}

.paragraph {
  font-size: 1.4rem;
}
